import React from 'react';
import styled from 'styled-components';
import { Ring } from 'react-spinners-css';

const LoaderBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const Loading = () => (
  <LoaderBackdrop>
    <Ring size={44} color="#2d3748" />
  </LoaderBackdrop>
);

export default Loading;
