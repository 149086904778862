import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createLink as createHttpUploadLink } from 'apollo-absinthe-upload-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

import cleanTypenameLink from './clean-typename-link';
import OAuth from './oauth';
import config from '../config';

const requestHandler = (uri: string, options: RequestInit | undefined) => {
  return fetch(uri, options).then((response: Response) => {
    // Token expired, refresh and replay the request
    if (response.status === 401) {
      const refreshToken = localStorage.getItem('refresh');

      if (refreshToken) {
        console.log('Token expired, attempting to refresh...');

        return OAuth.refreshAccessToken(refreshToken).then((newAccessToken: string) => {
          if (!options) options = {};

          // Set the updated authorization header
          options.headers = {
            ...options.headers,
            authorization: `Bearer ${newAccessToken}`
          };

          console.log('Replaying request...');

          return fetch(uri, options);
        });
      } else {
        // Clear local storage as no refresh token available
        localStorage.clear();
      }
    }

    return response;
  });
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  };
});

const errorLink = onError(console.log);

const httpLink = createHttpUploadLink({
  uri: config.apiUrl + '/graphql',
  fetch: requestHandler
});

const client = new ApolloClient({
  link: ApolloLink.from([cleanTypenameLink, errorLink, authLink, httpLink]),
  cache: new InMemoryCache()
});

export default client;
