import { ApolloLink } from 'apollo-link';

// Clean the typename from variables before performing an action
// https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
const cleanTypenameLink = new ApolloLink((operation: any, forward: any) => {
  if (operation.variables) {
    //console.log('before', operation.variables);
    // const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
    operation.variables = removeTypenameProperty(operation.variables);
    //console.log('after', operation.variables);
  }
  if (forward) {
    return forward(operation).map((data: any) => {
      return data;
    });
  }
});

const removeTypenameProperty = (value: any): any => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => removeTypenameProperty(v));
  } else if (typeof value === 'object' && value instanceof File !== true) {
    const newObj: any = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypenameProperty(v);
      }
    });
    return newObj;
  }
  return value;
};

export default cleanTypenameLink;
