import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const token = localStorage.getItem('token');

  const NextRoute = (props: RouteProps) => {
    if (token) {
      return <Component {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: props.location }
        }}
      />
    );
  };

  return <Route {...rest} render={NextRoute} />;
};

interface PrivateRouteProps extends RouteProps {
  component: any;
}

export default PrivateRoute;
