import config from '../config';
import client from './apollo';

type TokenResponse = {
  access_token: string;
  refresh_token: string;
  expires_at: string;
};

let refreshPromise: Promise<string> | null = null;

export const authorize = (email: string, password: string, remember?: boolean) => {
  localStorage.clear();
  client.clearStore();

  const params = new FormData();
  params.append('grant_type', 'password');
  params.append('client_id', config.clientId);
  params.append('username', email);
  params.append('password', password);
  params.append('remember', remember ? 'true' : 'false');

  return fetch(config.apiUrl + '/oauth/token', { method: 'POST', body: params })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.access_token) {
        localStorage.setItem('token', json.access_token);

        if (remember && json.refresh_token) {
          localStorage.setItem('refresh', json.refresh_token);
        }

        return true;
      }

      localStorage.clear();
      return false;
    })
    .catch(() => {
      localStorage.clear();
      return false;
    });
};

export const revoke = () => {
  const params = new FormData();
  params.append('client_id', config.clientId);
  params.append('access_token', localStorage.getItem('token') || '');

  localStorage.clear();
  client.clearStore();

  return fetch(config.apiUrl + '/oauth/revoke', { method: 'POST', body: params })
    .then(() => {
      refreshPromise = null;
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const refreshAccessToken = (refreshToken: string) => {
  // Return the existing promise if a refresh is already in progress
  if (refreshPromise) {
    return refreshPromise;
  }

  console.log('Refreshing token');

  const params = new FormData();
  params.append('client_id', config.clientId);
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);

  // Perform a new token refresh request
  return (refreshPromise = fetch(config.apiUrl + '/oauth/token', { method: 'POST', body: params }).then((refreshResponse: Response) => {
    if (refreshResponse.ok) {
      return refreshResponse.json().then((token: TokenResponse) => {
        // Refresh successful, save and return the new tokens
        console.log('Refresh success');
        localStorage.setItem('token', token.access_token);
        localStorage.setItem('refresh', token.refresh_token);
        refreshPromise = null;

        return token.access_token;
      });
    } else {
      // Refresh failed, clear the local storage
      revoke();

      throw new Error('Failed to refresh access token');
    }
  }));
};

export default {
  authorize,
  revoke,
  refreshAccessToken
};
