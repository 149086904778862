const config = {
  metadata: {
    title: `Claralist`,
    tagline: `To-do list's, Tasks, Reminders and Notes`,
    description: `Work smarter and be more productive with Claralist's productivity tools, To-do list's, Tasks, Reminders and Notes.`,
    lang: `en`,
    twitter: `@claralistapp`
  },
  version: process.env.REACT_APP_VERSION || ``,
  host: process.env.REACT_APP_HOST || ``,
  clientId: process.env.REACT_APP_CLIENT_ID || ``,
  apiUrl: process.env.REACT_APP_API_URL || ``
};

export default config;
