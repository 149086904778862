import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Normalize } from 'styled-normalize';

import apollo from './services/apollo';
import GlobalStyles from './components/global-styles';
import PrivateRoute from './components/private-route';
import Loading from './screens/loading';

const loadAsync = (name: string) => lazy(() => import(`${name}`));

const App: React.FC = () => {
  return (
    <ApolloProvider client={apollo}>
      <Router>
        <Normalize />
        <GlobalStyles />

        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact={true} path="/auth/login" component={loadAsync('./screens/login')} />
            <Route exact={true} path="/auth/lost-password" component={loadAsync('./screens/lost-password')} />
            <Route exact={true} path="/auth/reset-password/:token" component={loadAsync('./screens/reset-password')} />
            <Route exact={true} path="/verify-email/:token" component={loadAsync('./screens/verify-email')} />

            <PrivateRoute exact={true} path="/workspaces/:id" component={loadAsync('./screens/workspace')} />
            <PrivateRoute exact={true} path="/account" component={loadAsync('./screens/account')} />
            <PrivateRoute exact={true} path="/" component={loadAsync('./screens/home')} />

            {/* Catch everything else and show the NotFound page */}
            <Route component={loadAsync('./screens/404')} />
          </Switch>
        </Suspense>
      </Router>
    </ApolloProvider>
  );
};

export default App;
